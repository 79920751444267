<template>
  <nav
    class="navbar fixed-top navbar-expand navbar-light shadow d-none d-md-flex px-0 border-0 py-4"
    id="topbar"
  >
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="d-flex align-items-center justify-content-between">
            <router-link
              :to="$paths.landing.home"
              class="navbar-brand text-start d-flex align-items-center"
            >
              <img
                src="@/assets/img/logo-navbar.svg"
                alt=""
                style="min-height: 40px"
              />
            </router-link>

            <nav aria-label="breadcrumb" class="me-auto ms-4">
              <ol class="breadcrumb fw-bold">
                <router-link
                  v-slot="{ navigate }"
                  custom
                  v-if="link"
                  :to="link"
                >
                  <li
                    @click="navigate"
                    class="breadcrumb-item d-flex align-items-center"
                  >
                    <a>
                      {{ linkName }}
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ navigate }"
                  custom
                  v-if="sublink"
                  :to="sublink"
                >
                  <li
                    @click="navigate"
                    class="breadcrumb-item d-flex align-items-center"
                  >
                    <a>{{ sublinkName }}</a>
                  </li>
                </router-link>
                <li
                  class="breadcrumb-item active d-flex align-items-center"
                  aria-current="page"
                >
                  {{ current }}
                </li>
              </ol>
            </nav>

            <router-link
              :to="$paths.landing.bundles.list"
              class="nav-link me-4"
            >
              Turmas
            </router-link>

            <router-link :to="$paths.landing.approveds" class="nav-link me-4">
              Aprovados
            </router-link>

            <router-link :to="$paths.landing.contact" class="nav-link me-4">
              Contato
            </router-link>

            <router-link :to="$paths.dashboard.home" class="nav-link">
              <button type="button" class="btn btn-outline-primary">
                Área do aluno
              </button>
            </router-link>

            <div class="nav-item">
              <div class="vr d-none d-lg-flex h-100 mx-lg-4 text-muted"></div>
              <!-- <hr class="d-lg-none my-1 text-muted"> -->
            </div>

            <template v-if="!userSession">
              <router-link :to="$paths.dashboard.home" class="nav-link">
                <button type="button" class="btn btn-primary">Entrar</button>
              </router-link>
            </template>

            <template v-if="userSession">
              <div class="navbar-user">
                <div
                  class="dropdown cursor-pointer align-items-center d-flex"
                  @mouseover="avatarOver"
                  @mouseleave="avatarLeave"
                >
                  <span class="me-2 d-block">{{ userSession.nickname }}</span>
                  <div
                    class="avatar avatar-sm"
                    v-if="userSession.profile_picture"
                  >
                    <img
                      :src="userSession.profile_picture"
                      alt=""
                      class="avatar-img rounded-circle"
                    />
                  </div>
                  <i
                    v-else
                    class="fa-solid fa-user-circle text-primary"
                    style="font-size: 40px"
                  ></i>
                  <div class="dropdown-menu dropdown-menu-end cursor-pointer">
                    <router-link
                      :to="$paths.dashboard.myAccount"
                      class="dropdown-item"
                    >
                      Minha conta
                    </router-link>
                    <hr />
                    <span @click="logout" class="dropdown-item text-danger"
                      >Sair</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
export default {
  name: "Navbar",
  props: {
    link: String,
    linkName: String,
    sublink: String,
    sublinkName: String,
    current: String,
  },
  computed: {
    userSession() {
      return this.$store.state.session;
    },
  },
  data() {
    return {
      isLandingPage: [
        "home",
        "about",
        "approveds",
        "benefits",
        "contact",
        "exam",
      ].includes(this.$route.name),
    };
  },
  methods: {
    async logout() {
      this.$router.push(this.$paths.dashboard.login);
      // try {
      //   await this.$userService.logout()
      // } catch (error) {
      //   console.log('ERRO NO LOGOUT', error)
      // } finally {
      // }
    },
    avatarOver() {
      $(".dropdown-menu").addClass("show");
      $(".dropdown-menu").attr("data-bs-popper", true);
    },
    avatarLeave() {
      $(".dropdown-menu").removeClass("show");
      $(".dropdown-menu").removeAttr("data-bs-popper");
    },
    async handleFreeExam() {
      try {
        await this.$examsService.getFreeExam();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: unset;
  /* flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; */
}

.navbar > [class*="container"] {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
</style>
