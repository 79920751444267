import NetworkService, { Endpoint } from './NetworkService'

export default class LandingService {
    private network

    constructor() {
        this.network = new NetworkService()
    }

    listBanners = () => {
        return this.network.get(Endpoint.landingBanners)
    }
    listApproveds = () => {
        return this.network.get(Endpoint.approveds)
    }
    listTestimonials = () => {
        return this.network.get(Endpoint.testimonials)
    }
    listGeneralTestimonials = () => {
        return this.network.get(Endpoint.generalTestimonials)
    }
    listInfos = async () => {
        const res = await this.network.get(Endpoint.settings)
        let infosObj = {}
        res.data.forEach((elem: any) => {
            infosObj[elem.key] = elem.value
        })
        res.data = infosObj
        return res
    }
}