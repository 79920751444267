<template>
  <nav
    class="navbar navbar-vertical fixed-start navbar-expand-md d-md-none fixed-top navbar-light border-0"
    id="sidebar"
  >
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <router-link
        :to="$paths.landing.home"
        class="navbar-brand text-start d-flex align-items-center"
      >
        <img
          class="navbar-brand-img rounded"
          src="@/assets/img/logo-primary.svg"
          alt="Leburma"
        />
      </router-link>

      <div class="navbar-user d-md-none" v-if="userSession">
        <div class="dropdown cursor-pointer align-items-center d-flex">
          <span class="text-subtitle me-2">{{ userSession.nickname }}</span>
          <a
            href="#"
            id="sidebarIcon"
            class="dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div
              class="avatar avatar-sm d-flex align-items-center justify-content-end"
            >
              <img
                v-if="userSession.profile_picture"
                :src="userSession.profile_picture"
                alt=""
                class="avatar-img rounded-circle"
              />
              <i
                v-else
                class="fa-solid fa-user-circle text-primary"
                style="font-size: 40px"
              ></i>
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="sidebarIcon"
          >
            <router-link :to="$paths.dashboard.myAccount" class="dropdown-item">
              Minha conta
            </router-link>
            <hr />
            <span @click="logout" class="dropdown-item text-danger">Sair</span>
          </div>
        </div>
      </div>
      <router-link
        :to="$paths.dashboard.login"
        class="text-subtitle btn btn-primary"
        v-else
      >
        Área do aluno
      </router-link>

      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <ul class="navbar-nav">
          <li class="nav-item" v-if="userSession">
            <router-link
              :to="$paths.dashboard.home"
              class="text-paragraph text-primary nav-link"
            >
              Área do aluno
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="$paths.landing.bundles.list"
              class="text-paragraph text-primary nav-link"
            >
              Turmas
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="$paths.landing.approveds"
              class="text-paragraph text-primary nav-link"
            >
              Aprovados
            </router-link>
          </li>
          <!-- <li class="nav-item">
              <router-link
                :to="$paths.landing.benefits"
                class="text-paragraph text-primary nav-link"
              >
                Benefícios
              </router-link>
            </li> -->
          <li class="nav-item">
            <router-link
              :to="$paths.landing.contact"
              class="text-paragraph text-primary nav-link"
            >
              Contato
            </router-link>
          </li>
          <!-- </template> -->
          <!-- <li class="nav-item">
            <a
              href="https://borrachoconcursos.com.br"
              target="_blank"
              class="text-paragraph text-primary nav-link"
            >
              Cursos
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    userSession() {
      return this.$store.state.session;
    },
  },
  data() {
    return {
      isLandingPage: [
        "home",
        "about",
        "approveds",
        "benefits",
        "contact",
        "exam",
      ].includes(this.$route.name),
    };
  },
  methods: {
    async logout() {
      this.$router.push(this.$paths.dashboard.login);
      // try {
      //   await this.$userService.logout()
      // } catch (error) {
      //   console.log('ERRO NO LOGOUT', error)
      // } finally {
      // }
    },
    async handleFreeExam() {
      try {
        await this.$examsService.getFreeExam();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.sidebar-icon {
  width: 25px;
  text-align: left;
}
</style>
